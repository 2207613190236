module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/mdurkovic/Work/Git/CoolApps/m-chemicals/locales","languages":["en","sk"],"defaultLanguage":"sk","siteUrl":"https://www.m-chemicals.sk","i18nextOptions":{"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"dark":false,"showFooter":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
